// extracted by mini-css-extract-plugin
export var fblogo = "footer-module--fblogo--201Fp";
export var twlogo = "footer-module--twlogo--17hNl";
export var inlogo = "footer-module--inlogo--1xxhI";
export var lilogo = "footer-module--lilogo--1Yzd6";
export var socialmedia = "footer-module--socialmedia--1NP_q";
export var footerlogo = "footer-module--footerlogo--1B6xy";
export var hmslogo = "footer-module--hmslogo--_tKUO";
export var thefooter = "footer-module--thefooter--TLo-4";
export var textbold = "footer-module--textbold--3y0iq";
export var text = "footer-module--text--2mJXU";
export var texttitle = "footer-module--texttitle--3hONs";
export var sociallogo = "footer-module--sociallogo--eZeog";